



















import Vue from 'vue'

export default Vue.extend({
    name: 'Steps',

    props: {
        steps: {
            type: Array as () => string[],
            required: true,
        },
        activeStepIndex: Number,
    },
})
