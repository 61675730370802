




import Vue from 'vue'
import { Steps } from '../Steps'

export default Vue.extend({
    name: 'StepsNetworker',

    components: { Steps },

    computed: {
        steps(): string[] {
            return [
                'Sign up',
                'Invite your network',
                'Invite people who might be open to an offer and get rewarded',
                'Refer people you know for specific jobs and get up to double rewards',
            ]
        },
    },
})
