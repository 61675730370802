

































import Vue from 'vue'
import { BaseSection, BaseFormAuthenticate } from '@/components'
import { createNamespacedHelpers } from 'vuex'
import { ROUTE, STORE_MODULE } from '@/enums'
import { IStateAuth, IStateOnboarding } from '@/types'
import { StepsCandidate, StepsNetworker } from './components'

const { mapState } = createNamespacedHelpers(STORE_MODULE.AUTH)
const { mapState: mapStateOnboarding } = createNamespacedHelpers(STORE_MODULE.ONBOARDING)

export default Vue.extend({
    name: 'SignIn',

    components: { BaseSection, BaseFormAuthenticate, StepsCandidate, StepsNetworker },

    props: {
        previousRoutePath: String,
        showStepsForNetworker: Boolean,
        showStepsForCandidate: Boolean,
    },

    computed: {
        ...(mapState(['userAccount']) as MapStateToComputed<IStateAuth>),
        ...(mapStateOnboarding(['onboardingState']) as MapStateToComputed<IStateOnboarding>),

        showSteps(): boolean {
            return this.showStepsForCandidate || this.showStepsForNetworker
        },
    },

    methods: {
        // NOTE: this is complete shit from original form, to be refactored
        redirectAfterSignIn() {
            const userRole = this.userAccount?.userRole

            if (userRole !== 'candidate' && !this.onboardingState.isCompanySetupCompleted) {
                return this.$router.push({ name: ROUTE.COMPANY_SETUP })
            }

            if (this.$route.query.job) {
                let url = `/job/${this.$route.query.job}`
                if (this.$route.query.ref) url += `?ref=${this.$route.query.ref}`
                this.$router.push(url)
            } else if (this.previousRoutePath) {
                this.$router.push(this.previousRoutePath)
            } else {
                this.$router.push({
                    name: userRole === 'candidate' ? ROUTE.DASHBOARD_PROFILE : ROUTE.DASHBOARD,
                })
            }
        },
    },
})
