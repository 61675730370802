




import Vue from 'vue'
import { Steps } from '../Steps'

export default Vue.extend({
    name: 'StepsCandidate',

    components: { Steps },

    computed: {
        steps(): string[] {
            return [
                'Sign up',
                'Set up your profile - select active or passive',
                'Set up new job criteria - whats important for you',
                'Invite good people and get people to',
            ]
        },
    },
})
